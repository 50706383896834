<template>
  <div
    class="select-dropdown"
    :tabindex="tabindex"
    @blur="open = false"
    @click="open = !open"
  >
    <div class="selected">{{ selected }}</div>
    <div class="options" :class="{ hide: !open }">
      <div
        v-for="(option, index) in options"
        :key="index"
        @click="selectOption(option)"
      >
        {{ option }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "BorrowReturnSelectDropdown",
  emits: ["select"],
  props: {
    options: {
      type: Array,
      required: true,
    },
    default: {
      type: String,
      required: false,
      default: null,
    },
    tabindex: {
      type: Number,
      required: false,
      default: 0,
    },
  },
  data() {
    return {
      selected: this.default
        ? this.default
        : this.options.length > 0
        ? this.options[0]
        : null,
      open: false,
    };
  },
  methods: {
    selectOption(option) {
      this.selected = option;
      this.$emit("select", option);
    },
  },
};
</script>

<style scoped>
.select-dropdown {
  position: relative;
  background-color: #e5e7e7;
  padding: 0.75rem 2rem 0.75rem 0;
  border-radius: 0.25rem;
  cursor: pointer;
}

.select-dropdown .selected {
  color: #000;
  font-size: 0.875rem;
  transition: all 0.2s ease-in-out;
  padding-left: 1em;
  user-select: none;
  font-size: 1em;
}

.select-dropdown .selected:after {
  position: absolute;
  content: "";
  top: 18px;
  right: 1em;
  width: 0;
  height: 0;
  padding: 3px;
  display: inline-block;
  border: solid #434343;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

.select-dropdown .options {
  position: absolute;
  top: 100%;
  margin-top: 0.5rem;
  left: 0;
  right: 0;
  z-index: 1;
  background-color: #f5f5f5;
  border-radius: 5px;
  filter: drop-shadow(0 0 0.5rem rgba(0, 0, 0, 0.2));
}

.select-dropdown .options div {
  text-align: left;
  padding: 0.5rem 1rem;
  transition: all 0.3s cubic-bezier(0.23, 1, 0.32, 1);
}

.select-dropdown .options div:hover {
  background-color: #e5e7e7;
  cursor: pointer;
}

.hide {
  display: none;
}
</style>
