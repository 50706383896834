<template>
  <div class="info-card position-relative cursor-pointer" @click="onCardClick">
    <div>
      <header class="text-start d-flex align-items-center gap-2">
        <div class="card-icon">
          <img :src="imageFile" alt="Card title icon" />
        </div>
        <div>{{ header }}</div>
      </header>

      <div class="fs-4">
        {{ amount.toLocaleString("en-US") }}
        <span class="fs-6">{{ $t("borrow.item") }}</span>
      </div>
    </div>

    <img
      class="back-arrow-icon"
      src="../../assets/BorrowReturn/arrow_right.svg"
      alt=""
    />
  </div>
</template>
<script>
export default {
  emits: ["card-click"],
  props: {
    header: {
      type: String,
      required: true,
    },
    amount: {
      type: Number,
      required: true,
    },
    imageFile: {
      type: String,
      required: false,
    },
  },
  methods: {
    onCardClick() {
      this.$emit("card-click");
    },
  },
};
</script>
<style lang="scss" scoped>
.info-card {
  width: 100%;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 15px;
  padding: 10px;
}

.card-icon {
  max-width: 2.2rem;
}

.card-icon img {
  width: 100%;
  height: auto;
  object-fit: contain;
}

.back-arrow-icon {
  position: absolute;
  right: 10px;
  bottom: 10px;
  width: 1.5rem;
}
</style>
