var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"dashboard-container"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 col-md-6"},[_c('dashboard-card',{attrs:{"header":_vm.$t('borrow.reserved_asset'),"amount":_vm.borrowReturnAssetStatusCounts.to_request,"imageFile":require('../../assets/BorrowReturn/need_prepare_outline.svg')},on:{"card-click":function($event){return _vm.$router.push({
            name: 'manage-order',
            query: {
              tab: 'prepare',
            },
          })}}})],1),_c('div',{staticClass:"col-12 col-md-6"},[_c('dashboard-card',{attrs:{"header":_vm.$t('borrow.need_recieve_asset'),"amount":_vm.borrowReturnAssetStatusCounts.to_send,"imageFile":require('../../assets/BorrowReturn/need_send_outline.svg')},on:{"card-click":function($event){return _vm.$router.push({
            name: 'manage-order',
            query: {
              tab: 'send',
            },
          })}}})],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 col-md-6"},[_c('dashboard-card',{attrs:{"header":_vm.$t('borrow.occupied_asset'),"amount":_vm.borrowReturnAssetStatusCounts.using,"imageFile":require('../../assets/BorrowReturn/clock_outline.svg')},on:{"card-click":function($event){return _vm.$router.push({
            name: 'manage-order',
            query: {
              tab: 'return',
            },
          })}}})],1),_c('div',{staticClass:"col-12 col-md-6"},[_c('dashboard-card',{attrs:{"header":_vm.$t('borrow.need_return_asset'),"amount":_vm.borrowReturnAssetStatusCounts.to_return,"imageFile":require('../../assets/BorrowReturn/clock_outline.svg')},on:{"card-click":function($event){return _vm.$router.push({
            name: 'manage-order',
            query: {
              tab: 'return',
            },
          })}}})],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }