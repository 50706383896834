<template>
  <div class="row">
    <div class="col-12 col-md-12 col-lg-8">
      <div class="donut-section h-100">
        <div class="row h-100 p-5">
          <div class="col-12 col-sm-12 col-md-12 col-lg-6">
            <div
              class="d-flex flex-column h-100 justify-content-center align-items-center"
            >
              <div>
                <apexchart
                  id="donut-chart"
                  type="donut"
                  :options="donutChartOptions"
                  :series="donutSeries"
                ></apexchart>
              </div>
            </div>
          </div>
          <div class="col-12 col-sm-12 col-md-12 col-lg-6">
            <div
              class="d-flex flex-column h-100 justify-content-center text-start"
            >
              <p class="text-grey">{{ $t("borrow.all_list") }}</p>
              <h1 class="fw-normal">{{ totalBorrowReturnAssets }}</h1>
              <div
                v-for="(label, index) in donutChartOptions.labels"
                :key="label"
                class="d-flex justify-content-between align-items-center my-1"
              >
                <div class="d-flex align-items-center gap-2">
                  <span
                    class="dots"
                    :style="{
                      backgroundColor: donutChartOptions.colors[index],
                    }"
                  ></span>
                  <u class="fs-5 fw-light">
                    {{ label }}
                  </u>
                </div>
                <div class="text-grey fw-light">
                  {{ donutSeries[index] }} {{ $t("borrow.item") }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12 col-md-12 col-lg-4 dashboard-card-container">
      <dashboard-card
        :header="$t('borrow.need_prepare_asset')"
        :amount="borrowReturnAssetStatusCounts.to_prepare"
        :imageFile="require('@/assets/BorrowReturn/need_prepare_outline.svg')"
        @card-click="
          $router.push({
            name: 'manage-order',
            query: {
              tab: 'prepare',
            },
          })
        "
      ></dashboard-card>
      <dashboard-card
        :header="$t('borrow.need_send_asset')"
        :amount="borrowReturnAssetStatusCounts.to_send"
        :imageFile="require('@/assets/BorrowReturn/need_send_outline.svg')"
        @card-click="
          $router.push({
            name: 'manage-order',
            query: {
              tab: 'send',
            },
          })
        "
      ></dashboard-card>
      <dashboard-card
        :header="$t('borrow.need_return_asset')"
        :amount="borrowReturnAssetStatusCounts.to_return"
        :imageFile="require('@/assets/BorrowReturn/clock_outline.svg')"
        @card-click="
          $router.push({
            name: 'manage-order',
            query: {
              tab: 'return',
            },
          })
        "
      ></dashboard-card>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import moment from "moment";
import VueApexCharts from "vue-apexcharts";
import DashboardCard from "../../components/BorrowReturn/DashboardCard.vue";
import { authHeader } from "../../store/actions";
import { borrowUrl } from "@/util/backend";

export default {
  name: "AdminDashboard",
  data() {
    return {
      totalBorrowReturnAssets: 0,
      borrowReturnAssetStatusCounts: {
        to_prepare: 0,
        to_return: 0,
        to_send: 0,
      },
      donutSeries: [0, 0, 0],
      donutChartOptions: {
        colors: ["#92DEFB", "#F5BFC6", "#C0C0FA"],
        legend: {
          show: false,
        },
        chart: {
          type: "donut",
          fontFamily: "Mitr, sans-serif",
        },
        labels: [
          this.$t("borrow.free_status"),
          this.$t("borrow.occupied_status"),
          this.$t("borrow.not_ready_status"),
        ],
      },
    };
  },
  components: {
    DashboardCard,
    apexchart: VueApexCharts,
  },
  methods: {
    moment,
    async fetchBorrowReturnStatics(id_company) {
      try {
        const res = await axios(
          `${borrowUrl()}dashboard/statistic/company/id/${id_company}`,
          authHeader()
        );
        return {
          asset_count: res.data.asset_count,
          status_count: res.data.status_count,
        };
      } catch (error) {
        console.error(error);
      }
    },
  },
  async created() {
    const id_company = await this.$store.dispatch("getCompanyIdCookie");
    const staticsData = await this.fetchBorrowReturnStatics(id_company);

    const newDonutSeries = [
      staticsData.asset_count.available,
      staticsData.asset_count.unavailable,
      staticsData.asset_count.not_available,
    ];
    this.donutSeries = newDonutSeries;

    this.totalBorrowReturnAssets = staticsData.asset_count.total;

    this.borrowReturnAssetStatusCounts = {
      to_prepare: staticsData.status_count.to_prepare,
      to_return: staticsData.status_count.to_return,
      to_send: staticsData.status_count.to_send,
    };
  },
};
</script>

<style scoped lang="scss">
.text-grey {
  color: #7c7c7c;
}

.filter {
  border-radius: 10px 10px 10px 10px !important;
  background: linear-gradient(180deg, #007afe 0%, #013f81 100%) !important;
  border: none;
  width: 40px;
  height: 40px;
  img {
    padding: 0.3rem;
  }
}

.donut-section {
  box-shadow: 0px 1px 6px 1px rgba(0, 0, 0, 0.25);
  border-radius: 15px;
  // margin: 10px 0;
}

#donut-chart {
  width: 100%;
}

.borrow-bar-graph {
  background: #fff;
  box-shadow: 0px 1px 6px 1px rgba(0, 0, 0, 0.25);
  border-radius: 15px;
  padding: 20px;
  margin: 2rem 0;
}

.dots {
  display: inline-block;
  width: 14px;
  height: 14px;
  border-radius: 50%;
  &.free {
    background-color: #92defb;
  }
  &.occupied {
    background-color: #f5bfc6;
  }
  &.not-ready {
    background-color: #c0c0fa;
  }
}

.dashboard-card-container {
  display: grid;
  grid-template-columns: repeat(1fr, minmax(300px, 1fr));
  gap: 1rem;
}
</style>
