var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 col-md-12 col-lg-8"},[_c('div',{staticClass:"donut-section h-100"},[_c('div',{staticClass:"row h-100 p-5"},[_c('div',{staticClass:"col-12 col-sm-12 col-md-12 col-lg-6"},[_c('div',{staticClass:"d-flex flex-column h-100 justify-content-center align-items-center"},[_c('div',[_c('apexchart',{attrs:{"id":"donut-chart","type":"donut","options":_vm.donutChartOptions,"series":_vm.donutSeries}})],1)])]),_c('div',{staticClass:"col-12 col-sm-12 col-md-12 col-lg-6"},[_c('div',{staticClass:"d-flex flex-column h-100 justify-content-center text-start"},[_c('p',{staticClass:"text-grey"},[_vm._v(_vm._s(_vm.$t("borrow.all_list")))]),_c('h1',{staticClass:"fw-normal"},[_vm._v(_vm._s(_vm.totalBorrowReturnAssets))]),_vm._l((_vm.donutChartOptions.labels),function(label,index){return _c('div',{key:label,staticClass:"d-flex justify-content-between align-items-center my-1"},[_c('div',{staticClass:"d-flex align-items-center gap-2"},[_c('span',{staticClass:"dots",style:({
                    backgroundColor: _vm.donutChartOptions.colors[index],
                  })}),_c('u',{staticClass:"fs-5 fw-light"},[_vm._v(" "+_vm._s(label)+" ")])]),_c('div',{staticClass:"text-grey fw-light"},[_vm._v(" "+_vm._s(_vm.donutSeries[index])+" "+_vm._s(_vm.$t("borrow.item"))+" ")])])})],2)])])])]),_c('div',{staticClass:"col-12 col-md-12 col-lg-4 dashboard-card-container"},[_c('dashboard-card',{attrs:{"header":_vm.$t('borrow.need_prepare_asset'),"amount":_vm.borrowReturnAssetStatusCounts.to_prepare,"imageFile":require('@/assets/BorrowReturn/need_prepare_outline.svg')},on:{"card-click":function($event){return _vm.$router.push({
          name: 'manage-order',
          query: {
            tab: 'prepare',
          },
        })}}}),_c('dashboard-card',{attrs:{"header":_vm.$t('borrow.need_send_asset'),"amount":_vm.borrowReturnAssetStatusCounts.to_send,"imageFile":require('@/assets/BorrowReturn/need_send_outline.svg')},on:{"card-click":function($event){return _vm.$router.push({
          name: 'manage-order',
          query: {
            tab: 'send',
          },
        })}}}),_c('dashboard-card',{attrs:{"header":_vm.$t('borrow.need_return_asset'),"amount":_vm.borrowReturnAssetStatusCounts.to_return,"imageFile":require('@/assets/BorrowReturn/clock_outline.svg')},on:{"card-click":function($event){return _vm.$router.push({
          name: 'manage-order',
          query: {
            tab: 'return',
          },
        })}}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }