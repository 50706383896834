<template>
  <div class="dashboard-container">
    <div class="row">
      <div class="col-12 col-md-6">
        <dashboard-card
          :header="$t('borrow.reserved_asset')"
          :amount="borrowReturnAssetStatusCounts.to_request"
          :imageFile="
            require('../../assets/BorrowReturn/need_prepare_outline.svg')
          "
          @card-click="
            $router.push({
              name: 'manage-order',
              query: {
                tab: 'prepare',
              },
            })
          "
        ></dashboard-card>
      </div>
      <div class="col-12 col-md-6">
        <dashboard-card
          :header="$t('borrow.need_recieve_asset')"
          :amount="borrowReturnAssetStatusCounts.to_send"
          :imageFile="
            require('../../assets/BorrowReturn/need_send_outline.svg')
          "
          @card-click="
            $router.push({
              name: 'manage-order',
              query: {
                tab: 'send',
              },
            })
          "
        ></dashboard-card>
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-md-6">
        <dashboard-card
          :header="$t('borrow.occupied_asset')"
          :amount="borrowReturnAssetStatusCounts.using"
          :imageFile="require('../../assets/BorrowReturn/clock_outline.svg')"
          @card-click="
            $router.push({
              name: 'manage-order',
              query: {
                tab: 'return',
              },
            })
          "
        ></dashboard-card>
      </div>
      <div class="col-12 col-md-6">
        <dashboard-card
          :header="$t('borrow.need_return_asset')"
          :amount="borrowReturnAssetStatusCounts.to_return"
          :imageFile="require('../../assets/BorrowReturn/clock_outline.svg')"
          @card-click="
            $router.push({
              name: 'manage-order',
              query: {
                tab: 'return',
              },
            })
          "
        ></dashboard-card>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import DashboardCard from "./DashboardCard.vue";
import { authHeader } from "@/store/actions";
import { borrowUrl } from "@/util/backend";
export default {
  name: "UserDashboard",
  components: {
    DashboardCard,
  },
  data() {
    return {
      borrowReturnAssetStatusCounts: {
        to_request: 0,
        to_return: 0,
        to_send: 0,
        using: 0,
      },
    };
  },
  methods: {
    async fetchBorrowReturnStatics(id_company) {
      try {
        const res = await axios(
          `${borrowUrl()}dashboard/statistic/company/id/${id_company}`,
          authHeader()
        );
        const { status_count } = res.data;

        this.borrowReturnAssetStatusCounts.to_request = status_count.to_request;
        this.borrowReturnAssetStatusCounts.to_return = status_count.to_return;
        this.borrowReturnAssetStatusCounts.to_send = status_count.to_send;
        this.borrowReturnAssetStatusCounts.using = status_count.using;
      } catch (error) {
        console.error(error);
      }
    },
  },
  async created() {
    const id_company = await this.$store.dispatch("getCompanyIdCookie");
    await this.fetchBorrowReturnStatics(id_company);
  },
};
</script>

<style scoped>
.dashboard-container {
  max-width: 725px;
  margin: 0 auto;
}
</style>
